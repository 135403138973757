<template>
	<div :id="subnavHashId" style="margin-top: -1px">
		<HeaderClean />
		<div>
			<div id="content" class="container-fluid">
				<b-overlay :show="isLoading" z-index="9999">
					<router-view v-if="'Account__c' in booking"></router-view>
					<template #overlay>
						<div class="text-center">
							<font-awesome-icon class="text-primary fa-2x" :icon="['fas', 'music']" />
							<p class="text-primary font-weight-bold">Een muzikaal moment svp...</p>
						</div>
					</template>
				</b-overlay>
			</div>
		</div>
		<Footer></Footer>
	</div>
</template>

<script>
import {mapGetters} from "vuex";
import Footer from "@/components/customer/Footer";
import HeaderClean from "@/components/customer/HeaderClean";

export default {
	name: "CleanLayout",
	components: {
		Footer,
		HeaderClean,
	},
	computed: {
		...mapGetters({
			error: "getError",
			booking: "getBooking",
			isLoading: "getLoadingState",
		}),
		subnavHashId: function () {
			var routeName = this.$route.name;
			return routeName.toLowerCase();
		},
	},
};
</script>
